import styled from 'styled-components'
import Img from 'gatsby-image'
import { th, up, css } from '@smooth-ui/core-sc'

export const StyledLink = styled.div`
  a {
    color: red;
  }
  iframe {
    max-width: 100%;
  }
`

export const VerticalSpacer = styled.div`
  padding: ${({small, medium, large = true}) => small
    ?  `30px 0 15px 0`
    : medium
      ? `60px 0 30px 0`
      : large && `100px 0 50px 0`};
  text-align: center;
  ${({left}) => left && `text-align: left`};
`
export const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

export const MainSection = styled.section`
  position: relative;
  color: ${props => props.dark && th('white600')};
  background: ${props => props.dark && th('gray900')};
`

export const RoundedCornerImg = styled(Img)`
  border-radius: 5px;

  ${up('sm', css`
    border-radius: 20px;
  `)}
`

export const ImageCaption = styled.div`
  color: rgba(26, 26, 26, 0.8);
  font-size: 11px;
  
  a {
    color: red;
  }  
`

// Positioned 54px above each section, is used as a target for scrollIntoView()
// This helps scroll each section right under the header
export const SectionScrollAnchor = styled.div`
  position: absolute;
  top: -54px;
`

export const ErrorMessage = styled.span`
  color: #cc0000;
`

export const DateAndAuthor = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  
  ${up('lg', css`
    flex-direction: row;
  `)}
`

export const Author = styled.span`
  font-size: 24px;
  color: rgba(26,26,26,0.8);
  line-height: 28px;

  ${up('lg', css`
    margin-left: 10px;
    line-height: 36px;
  `)}
`
export const PublishedBy = styled.div`
  color: rgba(26,26,26,0.3);
  font-size: 18px;

  span {
    color: rgba(26,26,26,0.8);
  }
`

export const SpacerDesktop = styled.div`
  display: none;
  ${up('xl', css`
    display: block;
  `)}
`

export const RelatedDateAndAuthor = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  
   ${up('sm', css`
     flex-direction: row;
  `)}

  ${up('lg', css`
     font-size: 18px;
     flex-direction: column;
  `)}
`

export const RelatedDate = styled.span`
  color: rgba(26,26,26,0.3);
  line-height: 21px;
  margin-right: 6px;
`
export const RelatedAuthor = styled.span`
  color: rgba(26,26,26,0.8);
  line-height: 21px;
`