import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Grid, Row, Col, Normalize, Box } from '@smooth-ui/core-sc'

import { SiteMetadata } from '../components/SiteMetadata/SiteMetadata'
import { GlobalStyle } from "../style/theme"

import { RoundedCornerImg, VerticalSpacer } from '../style/globalStyleComponents'
import { renderDate } from '../utils/utils'
import { theme  } from '../style/theme'

export const query = graphql`
query ($id: String, $tags: [String!]!) {

  site {
    siteMetadata {
      siteUrl
    }
  }

  prismicBlogpost(id: {eq: $id}) {
    last_publication_date
    data {
      title {
        text
      }
      content {
        html
      }
      thumbnail {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }

  related: allPrismicBlogpost(filter: {tags:{in : $tags}}, limit: 3) {
		edges {
      node {
        uid
        last_publication_date
        data {
          content {
            text
          }
          title {
            text
          }
          thumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }

}
`

export default ({ data, location }) => {
  const post = data.prismicBlogpost.data
  
  return (
    <div>
      <SiteMetadata
        pathname={location.pathname}
        canonical={false} />
      <Normalize />
      <GlobalStyle />
      <Helmet
        title={post.title.text}
        meta={[{
          property: 'og:title',
          content: post.title.text,
        }, {
          property: 'og:image',
          content: `${data.site.siteMetadata.siteUrl}${post.thumbnail.localFile.childImageSharp.fluid.src}`,
        }]} />

      <Grid>
        <Row>
          <Col xs={12} gutter={{ xs: theme.gridGutter}}>
            <Box mb="40px">
              <h1>{post.title.text}</h1>
              <h3>{renderDate(data.prismicBlogpost.last_publication_date)}</h3>
            </Box>
            <RoundedCornerImg fluid={post.thumbnail.localFile.childImageSharp.fluid} />
            <div dangerouslySetInnerHTML={{__html: post.content.html}} />
          </Col>
        </Row>
      </Grid>

    </div>
  )
}